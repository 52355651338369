import React from 'react';
import {Chip, Grid, Typography} from '@mui/material';
import * as yup from 'yup';
import {AccessTime} from '@mui/icons-material';

const Time = ({ status }) => (
    <Grid item xs={2}>
        <Typography align='center' component={'div'} variant='body2' noWrap>
            {status.tp.cm && 'FT'}
            {status.tp.st === 'in' && <Chip label='Live' size='small' color={'error'} />}
            {status.tp.st === 'pre' && <AccessTime fontSize='small' />}
            {status.tp.n === 'STATUS_POSTPONED' && 'Postp.'}
        </Typography>
    </Grid>
);

Time.prototype = {
    status: yup.object().shape({
        dc: yup.string().required(),
        cl: yup.string().required(),
        p: yup.string().required(),
        tp: {
            cm: yup.boolean().required(),
            st: yup.string().required(),
            n: yup.string().required(),
        }
    })
};

export default React.memo(Time);
