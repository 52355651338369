import React from 'react';
import * as yup from 'yup';
import {Avatar, Grid, Typography} from '@mui/material';

export const TeamLogo = React.memo(function TeamLogoMemo({src, alt, size}) {
    return (
        <Grid item>
            <Avatar
                variant="square"
                sx={{
                    width: size ? 64 : 32,
                    height: size ? 64 : 32,
                }}
                src={`${src}${size ? '?w=128&h=128' : '?w=64&h=64'}`}
                alt={alt + ' Soccer Streams'}
            />
        </Grid>
    )
})

TeamLogo.prototype = {
    alt: yup.string(),
    src: yup.string(),
    size: yup.boolean()
};

export const TeamName = React.memo(({name, align}) => <Grid item xs zeroMinWidth><Typography variant="body1" align={align} noWrap>{name}</Typography></Grid>)

TeamName.prototype = {
    name: yup.string(),
    align: yup.string()
}

const Team = React.memo(({
        logo, displayName, name, home,
        size,
        align
    }) => <Grid item xs={5} container wrap="nowrap" direction={home ? "row-reverse" : "row"} alignItems="center" spacing={2}>
        <TeamLogo size={size} src={logo} alt={displayName}/>
        <TeamName name={name} align={align}/>
    </Grid>
)

Team.prototype = {
    logo: yup.string(),
    displayName: yup.string(),
    name: yup.string(),
    home: yup.boolean(),
    size: yup.boolean(),
    align: yup.string()
}

export default Team;
