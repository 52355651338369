import PropTypes from 'prop-types';
// material
import {CssBaseline, GlobalStyles as GlobalThemeStyles} from '@mui/material';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';

import React from "react";

/*const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    return <Link ref={ref} to={href} {...other} />;
});*/

const main = {
    shape: {
        borderRadius: 15
    },
    typography: {
        body1: {
            fontSize: '0.9rem'
        },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Ubuntu',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    },
    /*MuiLink: {
        defaultProps: {
            component: LinkBehavior,
        },
    },*/
};

export const light = {
    palette: {
        type: 'light',
        background: {
            default: '#edeef0',
            paper: '#fff'
        }
    },
    ...main
};

export const dark = {
    palette: {
        mode: 'dark'
    },
    ...main
};
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
    children: PropTypes.node
};

export default function ThemeConfig({ children }) {
    const theme = createTheme(dark);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalThemeStyles
                    styles={{
                        '*': {
                            margin: 0,
                            padding: 0,
                            boxSizing: 'border-box'
                        },
                        html: {
                            width: '100%',
                            height: '100%',
                            WebkitOverflowScrolling: 'touch'
                        },
                        body: {
                            width: '100%',
                            height: '100%'
                        },
                        '#root': {
                            width: '100%',
                            height: '100%'
                        },
                        input: {
                            '&[type=number]': {
                                MozAppearance: 'textfield',
                                '&::-webkit-outer-spin-button': {
                                    margin: 0,
                                    WebkitAppearance: 'none'
                                },
                                '&::-webkit-inner-spin-button': {
                                    margin: 0,
                                    WebkitAppearance: 'none'
                                }
                            }
                        },
                        textarea: {
                            '&::-webkit-input-placeholder': {
                                color: theme.palette.text.disabled
                            },
                            '&::-moz-placeholder': {
                                opacity: 1,
                                color: theme.palette.text.disabled
                            },
                            '&:-ms-input-placeholder': {
                                color: theme.palette.text.disabled
                            },
                            '&::placeholder': {
                                color: theme.palette.text.disabled
                            }
                        },

                        img: { display: 'block', maxWidth: '100%' },

                        // Lazy Load Img
                        '.blur-up': {
                            WebkitFilter: 'blur(5px)',
                            filter: 'blur(5px)',
                            transition: 'filter 400ms, -webkit-filter 400ms'
                        },
                        '.blur-up.lazyloaded ': {
                            WebkitFilter: 'blur(0)',
                            filter: 'blur(0)'
                        }
                    }}
                />
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
