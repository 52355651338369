import {applyMiddleware, compose, createStore} from 'redux';
import reducer from './reducer';
import thunk from 'redux-thunk';

// ==============================|| REDUX - MAIN STORE ||============================== //
const middleware = [thunk/*,logger*/];


const store = createStore(
    reducer,
    compose(
        applyMiddleware(
            ...middleware
        )
    )
);

export { store };
