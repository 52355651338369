import React from 'react'
import {Paper,Box} from "@mui/material";

export default class FootyLight extends React.PureComponent{

    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://ai-widget.s3.amazonaws.com/fl-ai-widget-soccerstreams100.js";
        script.setAttribute('async',"true")
        script.type = "text/javascript"

        document.getElementById('fl-ai-widget-placement').appendChild(script)
    }

    render() {
        return (<Box mt={3}>
            <Paper sx={{width: "100%", borderRadius: "15px !important",}}>
                <div id="fl-ai-widget-placement"></div>
            </Paper>
        </Box>)
    }
}
