import * as actionTypes from './types';
import {error, pre, success} from '../../_data/strings';
import {fetchGame} from './actions';
import {store} from '../index';

export const initialState = {
    data: {},
    state: pre
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const matchReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_GAME:
            store.dispatch(fetchGame(action.id))
            return state
        case actionTypes.FETCH_GAME_SUCCESS:
            return {
                ...state,
                data: action.payload,
                state: success
            };
        case actionTypes.FETCH_GAME_ERROR:
            return {
                ...state,
                state: error
            };
        case actionTypes.DESTROY_GAME.type:
            return {
                data: {},
                state: pre,
                stream: {},
                stream_state: pre
            }
        default:
            return state;
    }
};

export default matchReducer;
