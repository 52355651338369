import React from 'react';
import {Box, Grid} from '@mui/material';
import {ILineup} from './_types';
import AdUI from "../../../ui-component/AdUI";
import Roster from "./Roster";

const Lineup = React.memo(({ rst }) => {

    return (
        <>
            <AdUI format="auto"/>
            <Box mt={3}>
                <Grid container spacing={3}>
                    {rst[0].rst && <Roster {...rst[0]} />}
                    {rst[1].rst && <Roster {...rst[1]} />}
                </Grid>
            </Box>
        </>
    )
})
Lineup.prototype = ILineup
export default Lineup;
