import React, {useEffect} from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Alert
} from '@mui/material';
// import {sortStreams} from '../../../utils/helpers';
import {IStreams} from './_types';
import Streamer from "./Streamer";
import PremiumStream from "./PremiumStream";

const Streams = React.memo(({ streams }) => {

    const [notif,setNotif] = React.useState(true);

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(streams.length)
    const handleChangePage = (event, newPage) => setPage(newPage)
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    useEffect(() => {
        if (!("Notification" in window) || Notification.permission === "granted") {
            setNotif(true)
        }else{
            Notification.requestPermission().then(function(result) {
                if (result === 'granted' ) {
                    setNotif(true)
                }
            });
        }
    },[]);

    return (
        <Box mt={3}>
            <Paper sx={{width: "100%", borderRadius: "15px !important",}}>
                <TableContainer>
                    <Table sx={{minWidth: 650}} component="div" aria-label="simple table" size="small">
                        <TableHead component="div">
                            <TableRow component="div">
                                <TableCell component="div" size="medium" align="left">
                                    User
                                </TableCell>
                                <TableCell component="div" align="center">
                                    Quality
                                </TableCell>
                                <TableCell component="div" align="center">
                                    Lang
                                </TableCell>
                                <TableCell component="div" align="center">
                                    Ad
                                </TableCell>
                                <TableCell component="div" align="center">
                                    Nsfw
                                </TableCell>
                                <TableCell component="div" align="center">
                                    Channel
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody component="div">
                            {/*<Streamer
                                user="ScoreMate"
                                verified={true}
                                link="https://play.google.com/store/apps/details?id=pw.fedi.scoremate"
                                channel="Soccer Streams"
                                mobile={true}
                                adblock={false}
                                nsfw={false}
                                quality="HD"
                                ads={0}
                                language="Eng"
                                _id="ScoreMate"
                                uid={"ScoreMate"}
                                gid={"ScoreMate"}
                                game={"ScoreMate"} />*/}


                            { /*.sort(
                                (stream1, stream2) => sortStreams(stream1)
                                    .localeCompare(sortStreams(stream2)))*/
                                notif ? (rowsPerPage !== 0 ? streams.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : streams).map((row,_id) => (
                                row.u === "Ovopremium" ? <PremiumStream {...row} /> : <Streamer {...row} />
                            )) : (<TableRow><TableCell component="td" colSpan={6} align="center">
                                <Alert variant="filled" severity="error"> Notifications blocked. Please enable them in your browser To Access Streams Table </Alert>
                            </TableCell></TableRow>)}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div" rowsPerPageOptions={[5, 10, 25, 50, 100, { value: -1, label: "All" }]}
                    count={streams.length} rowsPerPage={rowsPerPage} page={page} colSpan={3}
                    SelectProps={{ inputProps: { "aria-label": "Links per page" }, native: true, }}
                    onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage}
                />
            </Paper>
        </Box>);
});
Streams.prototype = IStreams;
export default Streams;
