import React from 'react';
import {Grid, Typography} from '@mui/material';
import * as yup from 'yup';

const TeamName = ({ name, align }) => (
    <Grid item xs zeroMinWidth>
        <Typography variant='body1' align={align} noWrap>
            {name}
        </Typography>
    </Grid>);

TeamName.prototype = {
    name: yup.string().required(),
    align: yup.string()
};

export default React.memo(TeamName);
