import React from 'react';
import {DateTime} from 'luxon';
import * as yup from 'yup';
import Team from '../Team';
import {Chip, Grid, Typography} from '@mui/material';
import {AccessTime} from '@mui/icons-material';

const Time = React.memo(({date, status,homeScore,awayScore,isGame}) =>
        <Grid item xs={2}>
            {(status.st === "in" && isGame) && <Typography variant="subtitle1" align="center" noWrap style={{marginBottom:'5px'}}>
                {homeScore} - {awayScore}
            </Typography>}

            <Typography align="center" component={'div'} variant="body2" noWrap>
                {status.cm && 'FT'}
                {status.st === "in" && <Chip label="Live" size="small" color={'error'}/>}
                {status.st === "pre" && <AccessTime fontSize="small"/>}
                {status.n === 'STATUS_POSTPONED' && 'Postp.'}
            </Typography>

            {(status.s === "pre" || status.cm) && (
                <Typography variant="body1" align="center" noWrap>
                    {status.st === "pre" && DateTime.fromISO(date).toLocaleString({hour: "2-digit", minute: "2-digit", hour12: false})}
                    {status.cm && `${homeScore} - ${awayScore}`}
                </Typography>
            )}
        </Grid>
);

Team.prototype = {
    date: yup.string(),
    status: yup.object({ 
        cm: yup.boolean(),
        st: yup.string(),
        n: yup.string(),
    }),
    homeScore:yup.string(),
    awayScore:yup.string(),
    isGame:yup.boolean()
}

export default Time
