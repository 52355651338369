import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {pre, success} from "../../_data/strings";
import {FETCH_GAMES} from "../../store/home/types";
import {Box} from "@mui/material";
import {Helmet} from "react-helmet-async";
import Competition from "../Home/Competition";
import db from "../../_data/leagues.json";
import {useParams} from "react-router";
import AdUI from "../../ui-component/AdUI";

const League = () => {

    const { slug } = useParams();
    const { data, state } = useSelector(({ home }) => home);
    const dispatch = useDispatch();

    useEffect(() => {
        if (state === pre) {
            dispatch(FETCH_GAMES);
        }
    }, [state, dispatch]);

    const [league,setLeague] = useState(null);

    useEffect(() => {
        setLeague(db.find(l => l.slug === slug.replaceAll('-','.')))
    }, [slug,league]);

    return (
        <Box my={4}>
            {league && <Helmet prioritizeSeoTags>
                <title> {league.name} - Soccer Streams</title>
                <meta name="description" content={`Watch ${league.name} Soccer streams for free on Reddit Soccer Streams 100.Its the best alternative for Reddit Soccer Streams`}/>
                <link rel="canonical" href={window.location}/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image"
                      content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png"/>
                <meta property="og:title" content="Soccer Streams - Watch HD Soccer Streams For Free"/>
                <meta property="og:description"
                      content={`Watch ${league.name} Soccer streams for free on Reddit Soccer Streams .Its the best alternative for Reddit Soccer Streams`} />
                <meta property="og:url" content={window.location}/>
                <meta property="og:site_name" content="Soccer Streams"/>
                <meta name="twitter:card" content="summary_large_image"/>
            </Helmet>}

            {state === success && league && <Competition
                isLeague index={0}
                league={league.name}
                games={data.filter(leagues => leagues.lgn === league.name)[0]?.gs??[]}
            />}

            <AdUI format="auto"/>

        </Box>
    );
};

export default League;