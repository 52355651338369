import React, {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import {Breadcrumbs, Card, CardContent, CardHeader, Grid, Link, Skeleton, Typography} from '@mui/material';
import {logosrc} from '../../../utils/helpers';
import db from '../../../_data/leagues.json';
import {Link as Linkr} from "react-router-dom";
import {Home} from "@mui/icons-material";
import Team from "./Team";
import Time from "./Time";
import League from "../../Home/Competition/League";
import Loadable from "../../../ui-component/Loadable";

const PubStream = Loadable(React.lazy(() => import('../../../ui-component/PubStream')));

const MatchHeader = React.memo(function GameMemo({v, slug, a, h, d, hl, al, st}) {

    const [league,setLeague] = useState(null);

    useEffect(() => {
        setLeague(db.find(l => l.slug === slug.replaceAll('-','.')))
    }, [slug,league]);

    return (
        <Card sx={{
            width: '100%',
            marginBottom: 32 + 'px !important',
        }}>
        {league !== null && <League
            isHome={false}
            title={
                <Breadcrumbs aria-label="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList" >
                    <Link itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" component={Linkr} title={"Soccer Streams"} sx={{ display: 'flex', alignItems: 'center' }} underline="hover" color="inherit" to="/">
                        <Home sx={{ mr: 0.5 }} fontSize="inherit" /> Soccer Streams
                        <meta itemProp="item" content="https://main.soccerstreams-100.tv/"/>
                        <meta itemProp="position" content="1"/>
                        <meta itemProp="name" content={"Soccer Streams"} />
                    </Link>
                    <Link itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" component={Linkr} title={"Leagues"} underline="hover" color="inherit" to="/leagues">
                        <meta itemProp="item" content="https://main.soccerstreams-100.tv/leagues"/>
                        <meta itemProp="position" content="2"/>
                        <meta itemProp="name" content={"Leagues"} />
                        Leagues
                    </Link>
                    <Link itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" component={Linkr} title={league?.name} underline="hover" color="inherit" to={`/league/${slug}`}>
                        <meta itemProp="item" content={`https://main.soccerstreams-100.tv/league/${slug}`}/>
                        <meta itemProp="position" content="3"/>
                        <meta itemProp="name" content={league?.name} />
                        {league?.name}
                    </Link>
                    <Typography itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" color="text.primary">
                        <meta itemProp="item" content={window.location}/>
                        <meta itemProp="position" content="4"/>
                        <meta itemProp="name" content={`${h.t.n} vs ${a.t.n}`} />
                        {h.t.n} vs {a.t.n}
                    </Typography>
                </Breadcrumbs>
        }
            name={league?.name} logo={logosrc(league?.name)}
            subheader={DateTime.fromISO(d).toFormat('ff')}
        />}
        <CardContent>
            <Grid item container alignItems="center">
                <Team name={h.t.sd} align="center" logo={logosrc('team',hl|h.i)} size={true} displayName={h.t.n} home/>
                <Time isGame={true} date={d} status={st.tp || st} homeScore={h.sc} awayScore={a.sc}/>
                <Team name={a.t.sd} align="center" logo={logosrc('team',al|a.i)} size={true} displayName={a.t.n}/>
            </Grid>
            {v &&
                <Typography style={{marginTop: 15}} variant="body2" align="center" noWrap>
                    Venue : {v.fn}
                </Typography>
            }
            {/*{h && <PubStream/>}*/}
        </CardContent>
    </Card>)
})

export const MatchHeaderSkeleton = React.memo(function GameSkeletonMemo() {

    return (<Card>
            <CardHeader
                avatar={<Skeleton animation="wave" variant="circular" width={40} height={40}/>}
                subheader={<Skeleton animation="wave" height={10} width="30%" style={{marginBottom: 8}}/>}
                title={<Skeleton animation="wave" height={8} width="50%"/>}/>
            <CardContent>
                <Grid item container alignItems="center">
                    <Grid item xs={5} container direction="row-reverse" alignItems="center" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Skeleton animation="wave" variant="circular" sx={{width: 64, height: 64,}}/>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Skeleton animation="wave" height={10} width="70%" sx={{margin: 'auto'}}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton animation="wave" height={10} width="70%" sx={{margin: 'auto'}}/>
                    </Grid>
                    <Grid item xs={5} container wrap="nowrap" alignItems="center" spacing={2}>
                        <Grid item>
                            <Skeleton animation="wave" variant="circular" sx={{width: 64, height: 64,}}/>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Skeleton animation="wave" height={10} width="70%" sx={{margin: 'auto'}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>)
})

export default MatchHeader
