import * as yup from 'yup';

export const IStreamer = yup.object().shape({
    u: yup.string().required(),
    ui: yup.string().required(),
    v: yup.boolean().required(),
    gi: yup.string().required(),
    g: yup.string().required(),
    l: yup.string().required(),
    c: yup.string().required(),
    m: yup.boolean().required(),
    ad: yup.boolean().required(),
    n: yup.boolean().required(),
    q: yup.string().required(),
    a: yup.number().required(),
    ln: yup.string().required(),
})
export const IStreams = yup.object().shape({
    streams: yup.array(IStreamer).required()
})