import React, {lazy, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import {FETCH_GAMES} from '../../store/home/types';
import {pre, success} from '../../_data/strings';
import {Helmet} from 'react-helmet-async';
//import Search from "./Search";
//import Competition from "./Competition";
import Leagues from "./Leagues";
import Loadable from "../../ui-component/Loadable";

const Competition = Loadable(lazy(() => import('./Competition')));
const Search = Loadable(lazy(() => import('./Search')));
// ==============================|| MAIN LAYOUT ||============================== //

const Home = () => {

    const { data, state } = useSelector(({ home }) => home);
    const { liveOnly } = useSelector(({ main }) => main);
    const dispatch = useDispatch();


    useEffect(() => {
        if (state === pre) {
            dispatch(FETCH_GAMES);
        }
    }, [state, dispatch]);

    return (
        <Box my={4}>
            <Helmet  prioritizeSeoTags>
                <title>Soccer Streams - dedicated to the highest quality of free soccer / football streams</title>
                <link rel="canonical" href="https://m.soccerstreams100.io/" />
                <meta name="description" content="Soccer Streams A website dedicated to the highest quality of free soccer / football streams. PL Streams. best alternative for Reddit Soccer Streams"/>
            </Helmet>

            {state === success && <>
                <Search data={data} />
                <Leagues />
            </> }

            <div align="center" id="div-gpt-ad-8176806-5"></div>

            {state === success && data
                .filter((league) => liveOnly ? league.gs.filter(g => g.s.tp.st === "in").length > 0 : league)
                .map((league, index) =>
                    <Competition key={league['lgn']} index={index} league={league['lgn'].toString()}
                                 games={liveOnly ? league['gs'].filter(g => g.s.tp.st === "in") : league['gs']
                    }/>
            )}
        </Box>
    );
};

export default Home;
