import React from 'react';
import {Card, CardContent, Link, Typography} from '@mui/material';

export default function Footer() {
    return (
        <Card component={'footer'} sx={{padding: '40px', marginTop: '32px', borderRadius: '0px !important'}}>
            <CardContent>
                <Typography component={"h2"} align="center" variant={"h5"}>REDDIT SOCCER STREAMS</Typography>
                <Typography variant="body1" align="center">
                    Soccer Streams 100 is a website that offers live stream connections to some of the world's biggest and most popular sporting events. While it is mainly focused on soccer, it also broadcasts basketball, the NFL, mixed martial arts, and other live sporting events worldwide.
                </Typography>

                <Typography variant="body1" align="center">
                    Soccer Streams 100 is a popular website for people who want to watch soccer online, and it's now linked to the Reddit Soccer Streams page. Many users widely recognized it as the official backup for Reddit Soccer Streams.
                </Typography>

                <Typography variant="body1" align="center">
                    Many football fans are frustrated with paying to watch games only to be exposed to constant ads and advertisements throughout the game or, in many cases, black-out games that they can't even watch. Soccer stream 100 is an easier way to watch your favorite team play.
                </Typography>

                <Typography variant="body1" align="center">
                    <b>Soccer</b> is one of the most popular outdoor sports in the world. It is a sport that people worldwide enjoy playing and watching. As a result, the business for online live broadcasts of games has grown, and anyone may now watch professional soccer events from anywhere in the world. Soccer Streams 100 provides the most up-to-date live streaming the best options for sports fans.
                </Typography>

                <Typography variant="body1" align="center">
                    Matchday! You want to see your favorite team play, but there's no game on TV or even an online stream, and you don't want to pay for cable. Soccer Streams 100 Tv sports live streaming platform gives you the best viewing experience for all football events throughout the world. Here you can see all of the matches that are currently being played and learn about upcoming games.
                </Typography>

                <Typography variant="body1" align="center">
                    This platform streams 100 soccer matches every day from all of the leading football leagues worldwide. It contains EPL, EFL, English league championship, SERIE A, EURO CUP, COPA DEL REY, and even women's football league divisions. You can also see the results, current data, points table, statistics, and every goal scored by each team in the league for the matches that are currently being played. This makes keeping track of the league's progress much more accessible. It's a subscription-based website, so you'll need to sign up before you can start watching the feeds.
                </Typography>

                <Typography variant="body1" align="center">
                    What about watching the next Premier League game live? Soccer Streams 100 Tv sports live streaming platform provides soccer feeds to ensure you don't miss any of the excitement.
                </Typography>


                <Typography variant="body1" align="center">
                    Soccer Streams 100 Tv sports live streaming service is designed for computer displays rather than mobile screens; you will need to watch the live feeds on a personal computer or laptop. Watch Arsenal, Real Madrid, Napoli, PSG, Manchester United, Liverpool, Manchester City, Barcelona, Juventus, FC Porto, Inter Milan, and other teams live online.                  </Typography>

                <Typography variant="body1" align="center">
                    Soccer Streams 100 offers the best live streaming platform; join in and watch your favorite teams compete in your favorite sport. Soccer Streams 100 is a Reddit soccer stream and Reddit soccer thunder substitute.
                </Typography>

                <Typography variant="body1" align="center">
                    Soccer Streams 100 is dedicated to providing the finest quality soccer/football streams and is frequently recognized as the top Reddit Soccer Streams alternative.                  </Typography>

                <Typography variant="body2" color="text.secondary" align="center">
                    <Link color="inherit" href="/sitemap">
                        sitemap
                    </Link>
                </Typography>
            </CardContent>
        </Card>
    );
}