import React from 'react';
import {Avatar, Card, CardContent, CardHeader, Grid, IconButton, List} from '@mui/material';
import {ChevronLeft, ChevronRight} from '@mui/icons-material';
import {logosrc} from '../../../../utils/helpers';
import {IRoster} from '../_types';
import Player from "../Player";

const Roster = React.memo(({ frt, rst, t }) => {
    const [v, setV] = React.useState(0);
    const handleV = (newV) => setV((v+newV)%2);

    return (
        <Grid item xs={12} sm={6} md={6}>
            <Card style={{height: '100%'}}>
                <CardHeader
                    avatar={<Avatar alt={t?.n  + ' Soccer Streams'} src={logosrc('team',t.i)+'?w=32&h=32'} variant="circular" style={{width: 32, height: 32}}/>}
                    title={t.n+(v === 0 ? ' Line-Ups' : ' Substitutions')}
                    subheader={frt}
                    action={<>
                        <IconButton onClick={() => handleV(1)}><ChevronLeft/></IconButton>
                        <IconButton onClick={() => handleV(1)}><ChevronRight/></IconButton>
                    </>}
                />
                <CardContent style={{paddingTop: 0}}>
                    <List style={{paddingTop: '0'}} disablePadding dense>
                        {rst?.filter((row) => v === 0 ? row.srt : !row.srt).map((row) => (
                            <Player key={row.jsy} {...row} />
                        ))}
                    </List>
                </CardContent>
            </Card>
        </Grid>
    )
});

Roster.prototype = IRoster
export default Roster;
