import React from 'react';
import {Grid} from '@mui/material';
import * as yup from 'yup';
import TeamLogo from "./TeamLogo";
import TeamName from "./TeamName";

const Team = ({ logo, displayName, home, size, align }) => (
    <Grid item xs={5} container wrap='nowrap' direction={home ? 'row-reverse' : 'row'} alignItems='center' spacing={2}>
        <TeamLogo size={size} src={logo} alt={displayName} />
        <TeamName name={displayName} align={align} />
    </Grid>
);

Team.prototype = {
    logo: yup.string(),
    displayName: yup.string().required(),
    name: yup.string().required(),
    home: yup.boolean(),
    size: yup.boolean().required(),
    align: yup.string()
};

export default React.memo(Team);
