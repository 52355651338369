import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Link, List, Typography} from '@mui/material';
import * as yup from 'yup';
import {ExpandMore, Home} from '@mui/icons-material';
import {logosrc} from '../../../utils/helpers';
import League from "./League";
import Game from "./Game";
import {Link as Linkr} from "react-router-dom";

const Competition = ({ games, index, league,isSearch,isLeague }) => {
    const [expanded,setExpanded] = useState(index < 10)

    useEffect(() => {
        setExpanded(index < 10)
    },[index])

    return (
        <Accordion defaultExpanded={false} expanded={isLeague || isSearch?true:expanded} TransitionProps={{ unmountOnExit: true }} sx={{
            width: '100%',
            marginBottom: '32px !important',
            borderRadius: '15px !important',
            '&::before': {
                display: 'none'
            }
        }}>
            <AccordionSummary expandIcon={!isLeague && !isSearch && <ExpandMore />} onClick={() => setExpanded(!expanded)} >
                <League
                    isHome={true}
                    title={isSearch ? league : isLeague ?<Breadcrumbs itemScope itemType="https://schema.org/BreadcrumbList" aria-label="breadcrumb">
                        <Link itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"
                              component={Linkr} title={"Reddit Soccer Streams"} sx={{ display: 'flex', alignItems: 'center' }} underline="hover" color="inherit" to="/">
                            <Home sx={{ mr: 0.5 }} fontSize="inherit" /> Soccer Streams
                            <meta itemProp="item" content="https://main.soccerstreams-100.tv/"/>
                            <meta itemProp="position" content="1"/>
                            <meta itemProp="name" content={"Reddit Soccer Streams"} />
                        </Link>
                        <Link component={Linkr} title={"Leagues soccer Streams"} underline="hover" color="inherit" to="/leagues"
                              itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                                <meta itemProp="item" content="https://main.soccerstreams-100.tv/leagues"/>
                                <meta itemProp="position" content="2"/>
                            <meta itemProp="name" content={"Leagues"} />
                            Leagues
                        </Link>
                        <Typography color="text.primary" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                            <meta itemProp="item" content={window.location}/>
                            <meta itemProp="position" content="3"/>
                            <meta itemProp="name" content={league} />
                            {league}
                        </Typography>
                    </Breadcrumbs> : <Link component={Linkr} title={league+" live soccer streams"} underline="hover" color="inherit" to={`/league/${games[0].l.replace('.','-')}`}>
                        {league}
                    </Link> }
                    name={league} logo={logosrc(league)}
                    subheader={games.length === 1 ? '1 game' : games.length + ' games'} />
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0px 0px 16px', display: 'block' }}>
                {/*<AdUI format="horizontal"/>*/}
                <List component='div' sx={{width: '100%'}}>
                    {games.map((game,ii) => (
                        <Game adsload={false/*games.length > 3 && Math.round(games.length / 2) === ii+1*/}
                              key={league+game.i}
                              gameId={game.i}
                              status={game.s}
                              name={game.n}
                              slug={game.l}
                              date={game.d}
                              teams={game.c} />)
                    )}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

Competition.prototype = {
    league: yup.string().required(),
    games: yup.array().of(yup.mixed()),
    index: yup.number().required()
};

export default React.memo(Competition);
