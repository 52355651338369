import React from 'react';
import {SwapVert} from '@mui/icons-material';
import {ISub} from '../_types';

const Subbed = React.memo(({jsy, ath:{dn}}) =>
    <span style={{color:'#3d91fd'}}>
        <SwapVert color='inherit' fontSize='small' style={{verticalAlign: 'middle'}} />
        {jsy} {dn}
    </span>)

Subbed.prototype = ISub;
export default Subbed;
