import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ThemeConfig from "./theme";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import {HelmetProvider} from "react-helmet-async";

export const _url = "https://soccerstreams100.io/"

const cache = createCache({
    key: 'css',
    prepend: true,
});

ReactDOM.render(
    <Provider store={store}>
        <HelmetProvider>
            <CacheProvider value={cache}>
                <ThemeConfig>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeConfig>
            </CacheProvider>
        </HelmetProvider>
    </Provider>,
    document.getElementById('app')
);

serviceWorker.register();
