import * as yup from 'yup';

export const IAthlete = yup.object().shape({
    dn: yup.string().required(),
    fn: yup.string(),
    i: yup.string().required(),
    lsn: yup.string()
})
export const ISub = yup.object().shape({
    ath: IAthlete,
    jsy: yup.string(),
})
export const IPlay = yup.object().shape({
    cl: yup.object().shape({
        dv: yup.string(),
    }),
    ast:yup.boolean(),
    og: yup.boolean(),
    pen: yup.boolean(),
    rc: yup.boolean(),
    yc: yup.boolean(),
    spl: yup.boolean(),
    sub: yup.boolean(),
})
export const IPlayer = yup.object().shape({
    srt: yup.boolean().required(),
    jsy: yup.string(),
    ath: IAthlete,
    sbO: yup.boolean().required(),
    sbI: yup.boolean().required(),
    sbOf: ISub,
    sbIf: ISub,
    pls: yup.array(IPlay)
})
export const IRoster = yup.object().shape({
    frt: yup.string(),
    ha: yup.string().required(),
    rst: yup.array(IPlayer),
    t: yup.object()
})
export const ILineup = yup.object().shape({
    rst:yup.array(IRoster)
})