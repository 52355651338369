import React from 'react';
import * as yup from 'yup';
import {Avatar, CardHeader} from '@mui/material';

const League = ({ subheader, title, logo, action, name, isHome }) => {
    return (
        <CardHeader
            sx={{padding: isHome?0:'auto'}}
            avatar={<Avatar alt={name + ' Soccer Streams'} variant='square' sx={{ width: 32, height: 32 }} src={logo + '?w=32&h=32'} />}
            title={title} subheader={subheader}
            action={action}
        />
    );
};

League.prototype = {
    subheader: yup.string().required(),
    title: yup.string().required(),
    logo: yup.string(),
    action: yup.mixed(),
    name: yup.string().required(),
    isHome: yup.boolean().required()
};

export default React.memo(League);
