import {combineReducers} from 'redux';

// reducer import
import homeReducer from './home/reducer';
import main from './main';
import matchReducer from './match/reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    home: homeReducer,
    match: matchReducer,
    main
});

export default reducer;
