import React, {lazy, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import Loadable from '../../ui-component/Loadable';
import {Box} from '@mui/material';
import {error, pre, success} from '../../_data/strings';
import {DESTROY_GAME, FETCH_GAME} from '../../store/match/types';
import MatchHeader, {MatchHeaderSkeleton} from './MatchHeader';
import {Helmet} from 'react-helmet-async';
import Streams from "./Streams";
import Lineup from "./Lineup";
import MatchStats from "./Stats";
import FootyLight from "./FootyLight";

//const MatchHeader = Loadable(lazy(() => import('./MatchHeader')));
//const Lineup = Loadable(lazy(() => import('./Lineup')));
//const Streams = Loadable(lazy(() => import('./Streams')));
const ErrorPage = Loadable(lazy(() => import('../ErrorPage')));

const Match = () => {

    const { id,slug } = useParams();
    const { data, state } = useSelector(({ match }) => match);
    const dispatch = useDispatch();

    useEffect(() => {
        if (state === pre) dispatch({ type:FETCH_GAME,id })
    }, [state, dispatch, id])

    useEffect(() => {
        return () => dispatch(DESTROY_GAME)
    },[dispatch])

    if (state === error){
        return <ErrorPage/>
    }
//                <meta name="description" content={`You can watch ${data.h.t.n.toString()} vs ${data.a.t.n.toString()} live stream online for free at Reddit Soccer Streams. Watch HD Soccer streams for free on Soccer Streams.`} />
    return (
        <Box my={4}>
            <Helmet prioritizeSeoTags>
                <link rel="canonical" href={window.location} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png" />
                <meta property="og:url" content={window.location} />
                <meta property="og:site_name" content="Soccer Streams" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="soccerstreams100" />
                <meta name="twitter:label2" content="Est. reading time" />
                <meta name="twitter:data2" content="1 minute" />
            </Helmet>
            { state === success && <Helmet prioritizeSeoTags>

                <title>{`${data.h.t.n.toString()} vs ${data.a.t.n.toString()} live stats - Soccer Stats`}</title>
                <meta property="og:title" content={`${data.h.t.n.toString()} vs ${data.a.t.n.toString()} live stats - Soccer Stats`} />
                <meta property="og:description" content={`You can watch ${data.h.t.n.toString()} vs ${data.a.t.n.toString()} live stats online for free at Reddit Soccer Stats.Watch HD Soccer Stats for free on Soccer Stats.`}/>
            </Helmet>}
            { state === success && data && <h1 style={{opacity:0,overflow:'hidden',height: 0}} >{`${data.h?.t?.n} vs ${data.a?.t?.n} Reddit Soccer Stats`}</h1> }
            { state === pre ? <MatchHeaderSkeleton/> : <MatchHeader slug={slug} {...data} /> }


            { state === success && data.s && <Streams streams={data.s} /> }
            { state === success && data.bsc?.ts[0]?.stsc?.length > 0 && <MatchStats ts={data.bsc.ts} />}
            { state === success && data.rst && <Lineup rst={data.rst} /> }

        </Box>
    );
}
/*
class PubStream extends React.PureComponent {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://getafilenow.com/script_include.php?id=1178620";
        script.type = "text/javascript"
        document.getElementById("nwmdddvdv").appendChild(script)
    }
    render() {
        return <Box mt={3} id="nwmdddvdv" align="center"/>
    }
}*/

export default Match
