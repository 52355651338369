import React from 'react';
import {TableCell, TableRow, Tooltip} from '@mui/material';
import {
    StarsRounded as StarsIcon,
    HdRounded as HdIcon,
    MobileFriendlyRounded as MobileFriendlyIcon,
    VerifiedUserRounded as VerifiedUser,
} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {IStreamer} from './_types';

const useStyles = makeStyles(() => ({
    root: {
        color: "#4caf50",
        fontWeight: "bold",
        // fontSize: "1rem",
    },
    streamLink: {
        textDecoration: "none",
        // fontSize: "1.2rem",
    },
    iconSvg: {
        "&icosvg": {
            marginRight: "4px",
        },
    },
    verified: {
        // fontSize: "1.2rem",
        color: "#4caf50",
        fontWeight: "bold",
        paddingLeft: "0",
        "&::before": {
            marginRight: "10px",
            content: '""',
            //position: 'absolute',
            borderWidth: "0px 0px 0px 5px",
            borderStyle: "solid",
            borderColor: "#4caf50",
            //borderColor: 'rgb(255, 138, 0)',
            height: "25px",
            //left: '0px',
            borderRadius: "0 20px 20px 0",
        },
    },
}));

const PremiumStream = React.memo(({ u, v, l, c, m, q, a, ln, n, ad, }) => {
    const classes = useStyles();
    return (
        <TableRow
            hover
            className={classes.streamLink}
            component="a"
            href={l}
            target="_blank"
            rel="noopener nofollow"
        >
            <TableCell
                component="div"
                align="left"
                className={classes.verified}
            >
                Ovopremium
            </TableCell>

            <TableCell component="div" align="center" className={classes.iconSvg}>
                <Tooltip title="Premium" placement="top-start">
                    <StarsIcon
                        style={{ color: "#4caf50" }}
                        color="inherit"
                        fontSize="small"
                    />
                </Tooltip>
                <Tooltip title="Verified" placement="top-start">
                    <VerifiedUser
                        style={{ color: "#4caf50" }}
                        color="inherit"
                        fontSize="small"
                    />
                </Tooltip>
                <Tooltip title="1080pHD" placement="top-start">
                    <HdIcon fontSize="small" />
                </Tooltip>
                <Tooltip title="Mobile Friendly" placement="top-start">
                    <MobileFriendlyIcon fontSize="small" />
                </Tooltip>
            </TableCell>
            <TableCell component="div" align="center" className={classes.root}>
                {ln}
            </TableCell>
            <TableCell component="div" align="center" className={classes.root}>
                No
            </TableCell>
            <TableCell component="div" align="center" className={classes.root}>
                No
            </TableCell>
            <TableCell component="div" align="center" className={classes.root}>
                {c.substr(0, 30)}
            </TableCell>
        </TableRow>
    );
});

PremiumStream.prototype = IStreamer;

export default PremiumStream;
