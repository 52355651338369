import React from 'react';
import {TableCell, TableRow, Tooltip} from '@mui/material';
import {
    Block as BlockIcon,
    Hd as HdIcon,
    HighQuality as HqIcon,
    MobileFriendly as MobileFriendlyIcon,
    VerifiedUser
} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {IStreamer} from '../_types';

const useStyles = makeStyles(() => ({
    root: {},
    streamLink: {
        textDecoration: "none",
    },
    iconSvg: {
        "&icosvg": {
            marginRight: "4px",
        },
    },
    verified: {
        paddingLeft: "0",
        "&::before": {
            marginRight: "10px",
            content: '""',
            //position: 'absolute',
            borderWidth: "0px 0px 0px 5px",
            borderStyle: "solid",
            borderColor: "rgb(61 145 253)",
            //borderColor: 'rgb(255, 138, 0)',
            height: "25px",
            //left: '0px',
            borderRadius: "0 20px 20px 0",
        },
    },
}));

const Streamer = React.memo(({ u, v, l, c, m, q, a, ln, n, ad, }) => {
    const classes = useStyles();
    return (
        <TableRow
            hover
            className={classes.streamLink}
            component="a"
            href={l}
            target="_blank"
            rel="noopener nofollow"
        >
            <TableCell
                component="div"
                align="left"
                className={v ? classes.verified : classes.root}
            >
                {u /*=== "ovostreams"? "GoaLine" : u*/}
            </TableCell>

            <TableCell component="div" align="center" className={classes.iconSvg}>
                {v && (
                    <Tooltip title="Verified" placement="top-start">
                        <VerifiedUser
                            style={{ color: "#4caf50" }}
                            color="inherit"
                            fontSize="small"
                        />
                    </Tooltip>
                )}
                {q === "HD" && (
                    <Tooltip title="720pHD" placement="top-start">
                        <HdIcon fontSize="small" />
                    </Tooltip>
                )}
                {q === "SD" && (
                    <Tooltip title="SD" placement="top-start">
                        <HqIcon fontSize="small" />
                    </Tooltip>
                )}
                {m && (
                    <Tooltip title="Mobile Friendly" placement="top-start">
                        <MobileFriendlyIcon fontSize="small" />
                    </Tooltip>
                )}
                {ad && (
                    <Tooltip title="Adblock Disabled" placement="top-start">
                        <BlockIcon fontSize="small" />
                    </Tooltip>
                )}
            </TableCell>
            <TableCell component="div" align="center">
                {ln}
            </TableCell>
            <TableCell component="div" align="center">
                {a}
            </TableCell>
            <TableCell component="div" align="center">
                {n ? "Yes" : "No"}
            </TableCell>
            <TableCell component="div" align="center">
                {c.substr(0, 30)}
            </TableCell>
        </TableRow>
    );
});

Streamer.prototype = IStreamer;

export default Streamer;
