import {SET_LIVE, SET_ROUTES} from "./types";

export const initialState = {
    showt: true,
    liveOnly: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const matchReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ROUTES:
            return {
                ...state,
                showt: action.payload
            }
        case SET_LIVE:
            return {
                ...state,
                liveOnly: !state.liveOnly
            }
        default:
            return state;
    }
};

export default matchReducer;