import React from 'react';
import {List} from '@mui/material';
import ItemSiteWeb from "./ItemSiteWeb";

function Alternative() {
    const sites = [
        {"name":"Soccer Streams", "url":"https://streamsgate.tv/", "logo":"soccer.png"},
        {"name":"NBA Streams", "url":"https://nbastreams100.com", "logo":"nba.png"},
        {"name":"NFL Streams","url":"https://nflstreams100.com/","logo":"nfl.png"},
        {"name":"MLB Streams","url":"https://mlbstreams100.com/","logo":"mlb.png"},
        {"name":"NHL Streams","url":"https://new.nhlstreams100.com/","logo":"nhl.png"},
        {"name":"MMA Streams","url":"https://mmastreams100.com/","logo":"mma.png"},
        {"name":"Boxing Streams","url":"https://boxingstreams100.com","logo":"box.png"},
        {"name":"F1 Streams","url":"https://f1-100.com/","logo":"f1.png"},
        {"name":"MotoGP Streams","url":"https://motogpstreams100.com/","logo":"motogp.png"}
    ]
    return (
        <List component='div' sx={{paddingTop: '0', textAlign: 'center', /*position: 'sticky', top: 0, zIndex: 999, background: '#121212'*/}}>
            {sites.map((site) => <ItemSiteWeb key={site.name} {...site} />)}
        </List>
);
}

export default Alternative;
