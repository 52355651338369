import React from 'react';
import {Grid, Typography} from '@mui/material';
import * as yup from 'yup';
import {DateTime} from 'luxon';

const Score = ({ status, homeScore, awayScore, date }) => (
    <Grid item xs={2} container direction={'column'} alignItems='center'>
        {(status.tp.st === 'in' || (status.tp.cm && 'FT') || (status.tp.n === 'STATUS_POSTPONED')) && <>
            <Grid item xs>
                <Typography variant='body1' align='center' noWrap style={{ marginBottom: '5px' }}>
                    {homeScore}
                </Typography>
            </Grid>
            <Grid item xs>
                <Typography variant='body1' align='center' noWrap style={{ marginBottom: '5px' }}>
                    {awayScore}
                </Typography>
            </Grid>
        </>}
        {(status.tp.st === 'pre') && <Typography align='center' component={'div'} variant='body2' noWrap>
            {DateTime.fromISO(date).toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false })}
        </Typography>}
    </Grid>
);

Score.prototype = {
    status: yup.object().shape({
        dc: yup.string().required(),
        cl: yup.string().required(),
        p: yup.string().required(),
        tp: {
            cm: yup.boolean().required(),
            st: yup.string().required(),
            n: yup.string().required()
        }
    }),
    date: yup.string().required(),
    homeScore: yup.string().required(),
    awayScore: yup.string().required()
};

export default React.memo(Score);
