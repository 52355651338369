import React from "react";
import './style.css'
import Card from "@mui/material/Card";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const MatchStats = React.memo(function GameMemo({ts}) {

    const home = ts[0]
    const away = ts[1]

    const [show,setShow] = React.useState(false)

    const percentage = (x,y) => ((x / (x+y)) * 100).toFixed(2);

    return (
        <Card sx={{width: '100%', mb: 32 + 'px !important',mt:3}}>
            <div className="stats-holder football">
                <Table className="stats-teams">
                        <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                {home.t.sd}
                            </TableCell>
                            <TableCell align="center">
                                Stats
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                                {away.t.sd}
                            </TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {home?.stsc?.map((sts,i) => ((i > 5 && show) || (i<5)) && (
                            <TableRow key={sts.lbl}>
                                <TableCell sx={{width:'0%',px:2}} align="center">{home.stsc[i]?.dv}</TableCell>
                                <TableCell sx={{width:'40%',px:0}}><BorderLinearProgress variant="determinate" sx={{transform: "rotate(180deg)"}} value={percentage(parseFloat(home.stsc[i]?.dv),parseFloat(away.stsc[i]?.dv))??0} /></TableCell>
                                <TableCell sx={{width:'20%',px:2}} align="center">{sts.lbl}</TableCell>
                                <TableCell sx={{width:'40%',px:0}}><BorderLinearProgress variant="determinate" value={percentage(parseFloat(away.stsc[i]?.dv),parseFloat(home.stsc[i]?.dv))??0} /></TableCell>
                                <TableCell sx={{width:'0%',px:2}} align="center">{away.stsc[i]?.dv}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
            </div>
            <Typography sx={{"&:hover":{cursor: "pointer"}, p:1,display:"flex",justifyContent: 'center', alignContent: 'center', gap: 1, alignItems: 'center'}} onClick={() => setShow(!show)}>
                {show ? "Hide Stats": "Show All Stats"}
                <IconButton
                aria-label="expand row"
                size="small"
            >
                {show ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
            </IconButton></Typography>
        </Card>
    )
})

export default MatchStats
