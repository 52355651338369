import React, {useEffect} from 'react';
import {Card, Grid, Typography} from '@mui/material';
import {useLocation} from 'react-router-dom';
import {styled} from "@mui/material/styles";
import Menu from "./Menu";

const Line = styled(Grid)`
  background-color: #4caf50;
  height: 1px;
  width: 75px;
  flex: 1
`

export default function MainAppBar() {
    let location = useLocation();
    const [value, setValue] = React.useState(0);
    useEffect(() => setValue(['/','/advertise', 'streamer', '/rules', '/contact', '/dcma', '/privacy'].indexOf(location.pathname)), [setValue, location.pathname]);
    return (
        <Card sx={{marginBottom: '32px',borderRadius: '0 !important'}}>
            <Grid item container alignItems='center' sx={{height: '120px'}}>
                <Line item/>
                <Typography variant='h3' component='h1' sx={{padding: '0 40px'}}>Soccer Streams</Typography>
                <Line item/>
            </Grid>
            <div align="center" id="div-gpt-ad-8176806-4"></div>

            {/*<Box my={2} sx={{display:"flex",justifyContent:'center',}}>
                <a href="https://bit.ly/3zquoMp" target="_top" id="1159281">
                    <img src="https://a.impactradius-go.com/display-ad/5618-1159281" style={{maxWidth:'728px'}} border="0" alt="" width="100%"/>
                </a>
            </Box>*/}

            {/*<PubFuture id="628ff2e1564893002873b610" />
            <AdUIX format="horizontal"/>*/}
            <Menu value={value} />
        </Card>
    );
}
