import React from "react";
import {Avatar, Box, Stack} from "@mui/material";
import {Link} from "react-router-dom";

const Leagues = () => {
    const tops = [
        {id: 2,uid: "-dark/2",name: "Champions League",slug: "uefa-champions"},
        {id: 3,uid: "-dark/2310",name: "Europa League",slug: "uefa-europa"},
        {id: 4,uid: "-dark/23",name: "Premier League",slug: "eng-1"},
        {id: 5,uid: "-dark/15",name: "LaLiga Santander",slug: "esp-1"},
        {id: 6,uid: "-dark/12",name: "Serie A",slug: "ita-1"},
        {id: 7,uid: "-dark/10",name: "Bundesliga",slug: "ger-1"},
        {id: 8,uid: "-dark/9",name: "Ligue 1",slug: "fra-1"},
        {id: 8,uid: "-dark/4",name: "FIFA World Cup",slug: "fifa-world"}]
    return (
        <Box mb={4}>
            <Stack direction="row" justifyContent={"center"} spacing={2}>
                {tops.map(lg => (<Avatar
                    component={Link}
                    to={`/league/${lg.slug}`}
                    sx={{
                        width:'60px',
                        height:'60px',
                        border: '5px solid #1e1e1e',
                        padding: '5px',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 33%), 0px 1px 1px 0px rgb(0 0 0 / 68%), 0px 1px 3px 0px rgb(0 0 0)',
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                    }}
                    alt={lg.name} src={`https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500${lg.uid}.png?w=80&h=80`} variant={"circular"}/>))}
            </Stack>
        </Box>
    );
};

export default Leagues;