import Routes from './routes';
import React, {useEffect, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';
import useAdserv from "./utils/adsrv";


function App() {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    useEffect(() => {
        ReactGA.initialize("UA-84546669-10")
    },[]);
    useEffect(() => {
        ReactGA.pageview(location.pathname)
    }, [location]);
    useAdserv();
    return (<Routes />);
}

export default App;
