import React from 'react';
import {Avatar, Hidden, Link, ListItem} from '@mui/material';
import * as yup from 'yup';
import {styled} from "@mui/material/styles";

const ListItemStyled = styled(ListItem)`
  border-radius: 10%;
  display: inline-block;
  width: initial;
  padding: 15px
`

const AvatarStyled = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin-bottom:8px
`

const ItemSiteWeb = ({ url, name, logo }) => (
    <ListItemStyled component={Link} href={url} title={name} button color='inherit'>
        <div align="center">
            <AvatarStyled variant={"square"} alt={name} src={'/img/'+logo} />
            <Hidden only={['xs', 'sm']}><span>{name}</span></Hidden>
        </div>
    </ListItemStyled>
);

ItemSiteWeb.prototype = {
    url: yup.string().required(),
    name: yup.string().required(),
    logo: yup.string().required()
};

export default React.memo(ItemSiteWeb);
