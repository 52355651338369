import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import uuid from "../utils/uniqye";
import {Box} from "@mui/system";

const AdUI = ({format}) => {
    let location = useLocation();
    const [id,setId] = useState(location.key+uuid())
    useEffect(() => {
        setId(location.key+uuid())
    }, [location.key]);
    useEffect(() => {
        try {(window.adsbygoogle = window.adsbygoogle || []).push({});} catch (e) {}
    }, [id]);
    return (
        <Box id={id} my={2}>
            <React.Fragment key={id}>
                <ins id={id} className={'adsbygoogle'}
                     style={{display:'block', textAlign: 'center'}}
                     data-ad-client="ca-pub-1532998084673160"
                     data-ad-slot="8086136986"
                     data-full-width-responsive="true"
                     data-ad-format={format??"auto"}/>
            </React.Fragment>
        </Box>
    )
}
export default AdUI
