import {lazy} from 'react';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import {Navigate} from "react-router-dom";
import Home from "../views/Home";
import Match from "../views/Match";
import League from "../views/League";
import Leagues from "../views/Leagues";
import Sitemap from "../views/Sitemap";
import SitemapLink from "../views/Socccerstreams";

// dashboard routing
//const Home = Loadable(lazy(() => import('../views/Home')));
const Contact = Loadable(lazy(() => import('../views/Contact')));
const Dcma = Loadable(lazy(() => import('../views/Dcma')));
const Privacy = Loadable(lazy(() => import('../views/Privacy')));
const Rules = Loadable(lazy(() => import('../views/Rules')));
const Advertise = Loadable(lazy(() => import('../views/Advertise')));
//const Match = Loadable(lazy(() => import('../views/Match')));
//const League = Loadable(lazy(() => import('../views/League')));
//const Leagues = Loadable(lazy(() => import('../views/Leagues')));
// ==============================|| MAIN ROUTING ||============================== // <Nf />

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/sitemap',
            element: <Sitemap />
        },
        {
            path: '/soccerstreams/:id',
            element: <SitemapLink />
        },
        {
            path: '/event/:slug/:title/:id',
            element: <Match />
        },
        {
            path: '/league/:slug',
            element: <League />
        },
        {
            path: '/leagues',
            element: <Leagues />
        },
        {
            path: '/contact',
            element: <Contact />
        },
        {
            path: '/rules',
            element: <Rules />
        },
        {
            path: '/dcma',
            element: <Dcma />
        },
        {
            path: '/privacy',
            element: <Privacy />
        },
        {
            path: '/advertise',
            element: <Advertise />
        },
        {
            path: '*',
            element: <Navigate to="/" replace />
        }
    ]
};

export default MainRoutes;
