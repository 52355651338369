import React from "react";
import db from "../../_data/leagues.json";
import {Avatar, Breadcrumbs, Card, CardHeader, Link, List, ListItem, Typography} from "@mui/material";
import {Link as Linkr} from "react-router-dom";
import {Home} from "@mui/icons-material";
import {Box} from "@mui/system";
import {Helmet} from "react-helmet-async";
import AdUI from "../../ui-component/AdUI";

const Leagues = () => {
    return (
        <Box my={4}>

            <Helmet prioritizeSeoTags>
                <title>Top Leagues - Soccer Streams</title>
                <meta name="description" content="You can watch Top Leagues live stream online for free at Reddit Soccer Streams. Watch HD Soccer streams for free on Soccer Streams." />
                <meta property="og:title" content="Top Leagues - Soccer Streams" />
                <meta property="og:description" content={`You can watch Top Leagues live stream online for free at Reddit Soccer Streams.Watch HD Soccer streams for free on Soccer Streams.`}/>
                <link rel="canonical" href={window.location} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png" />
                <meta property="og:url" content={window.location} />
                <meta property="og:site_name" content="Soccer Streams" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="soccerstreams100" />
                <meta name="twitter:label2" content="Est. reading time" />
                <meta name="twitter:data2" content="1 minute" />
            </Helmet>

            <Card>
                <CardHeader avatar={<Home/>} title={<Breadcrumbs
                    itemScope itemType="https://schema.org/BreadcrumbList"
                    aria-label="breadcrumb">
                        <Link itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"
                            component={Linkr} title={"Soccer Streams"} sx={{ display: 'flex', alignItems: 'center' }} underline="hover" color="inherit" to="/">
                            <meta itemProp="item" content="https://new.soccerstreams-100.tv/"/>
                            <meta itemProp="position" content="1"/>
                            <meta itemProp="name" content={"Soccer Streams"} />
                            Soccer Streams
                        </Link>
                        <Typography color="text.primary" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                            <meta itemProp="item" content={window.location}/>
                            <meta itemProp="position" content="2"/>
                            <meta itemProp="name" content={"Leagues"} />
                            Leagues
                        </Typography>
                    </Breadcrumbs>} />
                <AdUI format="horizontal"/>
                <List dense disablePadding>
                    {db.map((d) =>
                        <ListItem key={d} button component={Linkr} to={`/league/${d.slug.replaceAll('.','-')}`}>
                            <CardHeader
                                avatar={<Avatar
                                    alt={d.name} variant="square"
                                    src={'https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/'+d.alternateId+'.png?w=80&h=80'}/>}
                                title={d.shortName}
                                subheader={d.name}
                            />
                        </ListItem>
                    )}
                </List>
            </Card>
        </Box>
    );

}

export default Leagues