import React from 'react';
import {ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from '@mui/material';
import {IPlayer} from '../_types';
import {red, yellow} from '@mui/material/colors';
import Subbed from "./Subbed";
import SportsSoccerIcon from "./SportsSoccerIcon";
import CardIcon from "./CardIcon";

const Player = React.memo(function PlayerMemo({pls,jsy,ath,sbOf,sbIf }) {
    return (
        <ListItem key={jsy} dense>
            <ListItemIcon>{jsy}</ListItemIcon>
            <ListItemText
                primary={ath.dn}
                secondary={
                    (sbOf && <Subbed {...sbOf} />) ||
                    (sbIf && <Subbed {...sbIf} />) ||
                    undefined
                }
            />
            {pls && <ListItemSecondaryAction>{pls?.map((play) =>
                (play.spl && !play.ast && <SportsSoccerIcon displayValue={play.cl.dv} />) ||
                (play.rc && <CardIcon color={red[500]} displayValue={play.cl.dv}/>) ||
                (play.yc && <CardIcon color={yellow[500]} displayValue={play.cl.dv}/>)
            )}</ListItemSecondaryAction>}
        </ListItem>
    )
})

Player.prototype = IPlayer

export default Player;
