import React from 'react';
import * as yup from 'yup';
import {Grid, ListItem} from '@mui/material';
import {Link} from 'react-router-dom';
import Time from "./Time";
import Team from "./Team";
import Score from "./Score";

const Game = ({ slug, teams, date, gameId, status,adsload,name }) => {
    const home = teams[0];
    const away = teams[1];
    return (
        <ListItem
            sx={{
                width: '100%',
                flexDirection: 'column',
                paddingBottom: '8px',
                '&:not(:last-child):after': {
                    background: 'rgba(255, 255, 255, 0.08)',
                    width: '100%',
                    height: '1px',
                    content: '""',
                    bottom: '0',
                    zIndex: '0',
                    overflow: 'hidden',
                    position: 'absolute'
                }
            }}
            button
            title={(name?name:(home.t.sd+' vs '+away.t.sd)) + " live soccer streams"}
            to={`/event/${slug.replaceAll('.','-')}/${escape((name?name:(home.t.sd+' vs '+away.t.sd))
                .replaceAll(' ','-').toLowerCase())+"-live-soccer-stats"}/${gameId}`}
            component={Link}
        >
            <Grid item container alignItems='center'>
                <Time status={status} />
                <Grid item xs container direction={'column'} alignItems='center' spacing={1}>
                    <Team logo={home.i} displayName={home.t.sd} name={home.t.n} align='inherit' size={false} />
                    <Team logo={away.i} displayName={away.t.sd} name={away.t.n} align='inherit' size={false} />
                </Grid>
                <Score date={date} status={status} homeScore={home.sc} awayScore={away.sc} />
            </Grid>
        </ListItem>
    );
};

Game.prototype = {
    adsload:yup.boolean,
    date: yup.string().required(),
    teams: yup.array().of(
        yup.object().shape({
            sc: yup.string().required(),
            ha: yup.string().required(),
            i: yup.string().required(),
            o: yup.number().required(),
            t: {
                n: yup.string().required(),
                ab: yup.string().required(),
                i: yup.string().required(),
                sd: yup.string().required()
            }
        })
    ),
    slug: yup.string().required(),
    gameId: yup.string().required(),
    status: yup.object().shape({
        displayClock: yup.string().required(),
        type: {
            completed: yup.string().required(),
            state: yup.string().required()
        }
    })
};

export default React.memo(Game);
