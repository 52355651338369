import {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {SET_ROUTES} from "../store/main/types";

function useAdserv() {
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        if (location.pathname.startsWith("/game/")){
            dispatch({type:SET_ROUTES,payload:false})
        } else {
            dispatch({type:SET_ROUTES,payload:true})
        }
    }, [location,dispatch]);
}

export default useAdserv