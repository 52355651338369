export const logosrc = (league,logos) => {

    const db = require('../_data/leagues.json');
    try {
        if(league === 'team'){
            return logos ?
                '/api/img/soccer/'+logos+'.png' :
                'https://cdn.statically.io/img/a3.espncdn.com/i/teamlogos/soccer/500/default-team-logo-500.png'
        }
        return db.find(l => l.name === league)['logos'][1]['href']
            .replace('a.espncdn.com', 'cdn.statically.io/img/a3.espncdn.com')
            .replace('http://', 'https://');
    } catch (e) {
        return 'https://cdn.statically.io/img/a.espncdn.com/redesign/assets/img/icons/ESPN-icon-soccer.png';
    }
};

export const sortStreams = (stream) => {
    switch (stream.u) {
        case 'ovostreams' :
            return 'a';
        case 'dubz' :
            return 'b';
        case 'FootyStreams':
            return 'c'
        case 'Weak__Spell' :
            return 'd';
        case 'cyclingentertainment' :
            return 'e';
        default :
            switch (!!stream.v) {
                case true :
                    return 'f';
                default :
                    return 'g';
            }
    }
};